import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client"

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link,
    useHistory,
    useRouteMatch,
    useParams
} from "react-router-dom";


export default function TestZone(props) {

  const ENDPOINT = "https://fltbd.herokuapp.com";
  const socket = io(ENDPOINT);
    

  const [messages, setMessages] = useState([]);
  const [room, setRoom] = useState('302')
  const [username, setUsername] = useState('');

    

    


  useEffect(() => {
    // Prompt the user to enter their name
    const username = prompt('Enter your name:');
    setUsername(username);

    // Join the room
    socket.emit('join', { username, room: room });

    // Listen for incoming messages
    socket.on('message', (data) => {
      setMessages((messages) => [...messages, data]);
    });

    // Leave the room when the component unmounts
    return () => {
      socket.emit('leave', { username, room: room });
      socket.disconnect();
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const message = event.target.elements.message.value;
    socket.emit('message', { username, room: room, message });
    event.target.elements.message.value = '';
  };

      return (
        <div>
      <h1>Room {room}</h1>
      <ul>
        {messages.map((message, index) => (
          <li key={index}>
            {message.username}: {message.message}
          </li>
        ))}
      </ul>
      <form onSubmit={handleSubmit}>
        <input type="text" name="message" placeholder="Type a message..." />
        <button type="submit">Send</button>
      </form>
    </div>

      )

}