import React, { useEffect, useState } from "react";


const PrivacyPolicy = () => {

    return (
        <>

            <h2>1. Introduction</h2>
            <p>
            Fltbd.com is committed to protecting the privacy of users of the Transportation Management System (TMS). This privacy policy sets forth the types of information we collect, how the information is used, and with whom the information may be shared.
            </p>
            <h2>2. Information Collection and Use</h2>
            <p>
                When you use the Fltbd.com TMS, we may collect personal information such as your name, address, phone number, and email address. We may also collect information about your use of the TMS, including your transportation and logistics processes.
            </p>
            <h2>3. Use of Information</h2>
            <p>
                The information collected through the Fltbd.com TMS will be used to provide and improve the Fltbd.com TMS, to communicate with you, and to comply with legal requirements. We may use your personal information to send you marketing and promotional materials, but you may opt-out of receiving such materials at any time.
            </p>
            <h2>4. Sharing of Information</h2>
            <p>
                We may share your personal information with our affiliates, service providers, and contractors for the purpose of providing and improving the Fltbd.com TMS. We may also disclose your personal information if required to do so by law or in response to a valid request by a government or law enforcement authority.
            </p>
            <h2>5. Data Security</h2>
            <p>
                We have implemented reasonable and appropriate security measures to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. However, no method of transmission over the internet, or method of electronic storage, is 100% secure, and we cannot guarantee its absolute security.
            </p>
            <h2>6. Changes to this Privacy Policy</h2>
            <p>
                We reserve the right to modify this privacy policy at any time. We will notify you of any material changes to this policy by posting the revised policy on the Fltbd.com TMS or by sending you an email. Your continued use of the Fltbd.com TMS after any such change constitutes your acceptance of the revised policy.
            </p>
            <h2>7. Contact Us</h2>
            <p>
                If you have any questions about this privacy policy or our use of your personal information, please contact us at info@fltbd.com.
            </p>
            <p>
            Last Updated: 2-2-2023.
            </p>
        </>
    )
}
export default PrivacyPolicy