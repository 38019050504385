import React, { Component, useState, setState, useEffect } from 'react';
import { useAuth, login } from "../../auth";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    Redirect
} from "react-router-dom"

function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 10)}`;
}

export default function SignUpConpanyInformation(props) {
    const [state, setState] = useState({})
    useEffect(() => {


        fetch(`https://fltbd.herokuapp.com/get-carrier-info/${props.state.companyDOT}`)
            .then(response => response.json())
            .then(data => {
                if (data.error_code === '1028') {
                    <Redirect to ={{path: props.jumpToStep(1)}}  />
                    props.parentDOTError(data)
                }
                else {
                    props.parentCompanyInformation(data);
                }
                    
                 

            })
            
           


    }, [])
    const [phoneNumber, setPhoneNumber] = useState("");

    const changeCompanyName = e => {
        props.parentCompanyName(e.target.value)
    }
    const changeCompanyMC = e => {
        props.parentCompanyMC(e.target.value)
    }


    const handleInput = (e) => {
        // this is where we'll call the phoneNumberFormatter function
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setPhoneNumber(formattedPhoneNumber);
        props.parentPhoneNumber(formattedPhoneNumber)
    };

    return (
        <div>
            <h2>Company Information</h2>
            <div class="mb-3">
                <label class="form-label">Company Name<small> *</small></label>
                <input type="text" name="company_name" class="form-control" placeholder="Company Name" value={props.state.companyName} onChange={changeCompanyName} />
            </div>
            <div class="mb-3">
                <label class="form-label">Company MC Number</label>
                <input type="number" name="company_mc" class="form-control" placeholder="MC Number" value={props.state.companyMC} onChange={changeCompanyMC} />
            </div>
            <div class="mb-3">
                <label class="form-label">Phone Number</label>
                <input class="form-control" onChange={(e) => handleInput(e)} value={props.state.phoneNumber} />
            </div>
            <div class="mb-3">
                <label class="form-label">Street Address</label>
                <small>Format Example: 123 Fake St, Fakeville, FK 12345</small>
                <textarea type="text" class="form-control" name="company_address" value={props.state.companyAddress} />
            </div>

        </div>
    )


}