import React, { Component, setState, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect
} from "react-router-dom";

export default class SignUpCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }


  changeCompanyDOT = e => {

    this.props.parentCompanyDOT(e.target.value)
  }


  render() {


    return (
      <>
        
        <h2>Enter DOT Number</h2>
        <small class="form-hint form-label">
          This will you help auto-fill information on the next page.
        </small>
        <div class="mb-3 mt-3">
          <input type="number" name="company_dot" class="form-control" placeholder="DOT Number" value={this.props.state.companyDOT} onChange={this.changeCompanyDOT} required />
          <div className="error-msg-red mt-3">{this.props.error}</div>
        </div>



      </>
    )
  }
}