import React, { useState, useEffect } from "react";
import { useHistory, withRouter, useParams } from "react-router-dom";
import { authFetch, logout } from '../../auth'
import CarriersItemCarrierDetails from './CarriersItemCarrierDetails';
import CarriersItemBillingDetails from './CarriersItemBillingDetails';
import CarrierDelete from './Modals/CarrierDelete';
import NotFound from '../Home/NotFound';
import CarriersItemEquipment from './CarriersItemEquipment';
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../Modals/LoadingModal";



export default function CarriersItem(props) {
  const params = useParams();
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(true)
  const [data, setData] = useState({ do_not_load: false, factor: "" })
  const [notFound, setNotFound] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  


  useEffect(() => {
    if (props && props.carriers) {
      const carrierInfo = props.carriers.filter(carrier => carrier.carrier_id == params.carrier_id)
      setData(carrierInfo[0])
      if (carrierInfo[0] === undefined) {
        setNotFound(true)
      }
      setShow(false)
      setLoading(false)
    }
  }, [props.carriers])


  const handleChangeDetails = (e) => {
    const mappedData = props.carriers.map(g => (g.carrier_id === params.carrier_id ? Object.assign({}, g, {
      [e.target.name]: e.target.value
    }) : g))
    setData({ ...data, [e.target.name]: e.target.value })


  }
  const handleCallbackCarrierPhone = (CarrierPhone) => {
    setData({ ...data, phone_number: CarrierPhone })
  }
  const handleCallBackBillingAddress = (MailAddress) => {
    setData({ ...data, billing_address: MailAddress })
  }
  const handleCallbackFederalID = (CarrierFederalID) => {
    setData({ ...data, federal_id: CarrierFederalID })
  }
  const handleCallBackFactor = (CarrierFactor) => {

    setData({ ...data, factor: CarrierFactor })



  }
  const handleCallBackFactoringCompany = (FactoringCompany) => {
    setData({ ...data, factoring_company: FactoringCompany })
  }


  const doNotLoadChange = (e) => {
    authFetch(`https://fltbd.herokuapp.com/api/carriers/${params.carrier_id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        do_not_load: !data.do_not_load,

      }),
      headers: { 'Content-Type': 'application/json' },
    })
    setData({ ...data, do_not_load: !data.do_not_load })
   updateCarrier(({ ...data, do_not_load: !data.do_not_load }))
  }

  const updateCarrier = (Details) => {
    const mappedData = props.carriers.map(g => (g.carrier_id === params.carrier_id ? Object.assign({}, g,
      Details) : g))
    props.updateCarriersList(mappedData)
  }
  const updateEquipment = (Details) => {
    //setData({ ...data, equipment: Details })

    console.log(JSON.stringify(Details))
    console.log(({ ...data, equipment: Details }))
    const mappedData = props.carriers.map(g => (g.carrier_id === params.carrier_id ? Object.assign({}, g,
     {equipment: JSON.stringify(Details)}) : g))
    props.updateCarriersList(mappedData)
    
    
  }

  return (
    <>
    <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">


      <div class="d-sm-flex">
        <h1>Manage Carrier</h1>
      </div>
      <div class="row">
        <div class="col-md-8">
          <CarriersItemCarrierDetails
            updateCarrier={updateCarrier}
            parentHandleChangeDetails={handleChangeDetails}
            parentCallbackCarrierPhone={handleCallbackCarrierPhone}

            {...data} />

          <CarriersItemBillingDetails
            updateCarrier={updateCarrier}
            parentCallbackBillingAddress={handleCallBackBillingAddress}
            parentCallbackFederalID={handleCallbackFederalID}
            parentCallbackFactor={handleCallBackFactor}
            parentCallbackFactoringCompany={handleCallBackFactoringCompany}
            {...data}
          />

        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Do Not Load</div>
              <label class="form-check form-switch">
                <input class="form-check-input" type="checkbox" onChange={doNotLoadChange} checked={data.do_not_load} />
                <span class="form-check-label">Load or Do Not Load</span>

              </label>
            </div>
          </div>
          <div class="card">
            <div class="card-header" style={{ border: "none" }}>
              <h3 class="card-title">Insurance</h3>
              <div class="edit-button">
                <small class="text-muted" >[edit]</small>
              </div>
            </div>
            <div class="card-body">
              <div class="card-subtitle">Cargo Insurance</div>
              <div class="card-subtitle">Primary </div>
              <div class="card-subtitle">Status</div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="card-title">Most Recent Load</div>
              <div class="card-subtitle">Load #</div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="card-title">Carrier Rating</div>
              <div class="card-subtitle">Rating: </div>
            </div>
          </div>

          <CarriersItemEquipment 
          updateEquipment={updateEquipment}
          equipment={data.equipment} {...data} />

          {props.roles == "agent" ?
            <button className="btn btn-danger btn-uppercase mt-3 float-right" disabled>Delete Carrier</button>
            :
            <CarrierDelete carrier_id={data.carrier_id} {...props} />
          }


        </div>


      </div>


    </div>
     <Modal show={show} size="sm" centered>

     <LoadingModal />

  </Modal>
</>

  )




}
