import React, { useEffect, useState } from "react";
import { useAuth, login } from "../../auth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect
} from "react-router-dom";


export default function SignUpCompanyType(props) {

  const [companyType, setCompanyType] = useState('')

  const changeCompanyType = (e) => {
    setCompanyType(e.target.value)
    props.parentCompanyType(e.target.value)
  }
  return (
    <>
      
      <h2>We are a ...</h2>
      <div class="form-check">
        <input class="form-check-input" id="carrier-check" value="carrier" checked={props.state.type === "carrier"} type="radio" onChange={changeCompanyType} disabled={true} />
        <label class="form-check-label form-label" for="carrier-check">
          Carrier
          <span class="form-check-description">
            <ul>
              <li>Create & Track Loads</li>
              <li>Track Equipment & Drivers</li>
            </ul>
          </span>
        </label>

      </div>
      <hr />
      <div class="alert alert-info" role="alert">
        We Are Currently Accepting Brokers
      </div>
      <div class="form-check">
        <input class="form-check-input" id="broker-check" value="broker" checked={props.state.type === "broker"} type="radio" onChange={changeCompanyType} />
        <label class="form-check-label form-label" for="broker-check">
          Broker
          <span class="form-check-description">
            <ul>
              <li>Create Rate Confiratmions</li>
              <li>Create Carrier Packets </li>
              <li>Send & Receive Documents Electronically</li>
              <li>Role Management</li>
            </ul>
          </span>
        </label>
      </div>
      <hr />
      <div class="form-check mb-4">
        <input class="form-check-input " id="both-check" value="both" checked={props.state.type === "both"} type="radio" onChange={changeCompanyType} disabled={true} />
        <label class="form-check-label form-label" for="both-check">
          Both
          <span class="form-check-description">You will get access to Carrier & Broker features</span>
        </label>
      </div>
    </>
  )

}