import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { authFetch } from "../../auth";
import axios from "axios";
import styled from "styled-components"
import RateConfirmationItem from "./RateConfirmationItem";

const Styles = styled.div`
.not-found-container {
    margin: 15px auto;
    padding: 70px;
    max-width: 850px;
    background-color: #fff;
    border: 1px solid #ccc;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
  }
  
  @media (max-width: 767px) {
    .not-found-container {
      padding: 35px 20px 70px 20px;
      margin-top: 0px;
      border: none;
      border-radius: 0px;
    }
  }`


class RateConfirmationContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notFound: false,
            isGenerating: false,
        }
    }
    handleCallbackGenerating = (PDFGeneration) => {
        this.setState({ isGenerating: PDFGeneration })
    }

    async getShippers() {
        const { load_id } = this.props.match.params
        const result = await axios.get(`https://fltbd.herokuapp.com/actions_shippers/${load_id}`)
        const shipperData = result.data

        const options = shipperData.map(d => ({

            "location_name": d.location_name,
            "load_match": load_id,
            "street_address": d.street_address,
            "city": d.city,
            "state": d.state,
            "contact_name": d.contact_name,
            "zipcode": d.zipcode,
            "phone_number": d.phone_number,
            "pickup_number": d.pickup_number,
            "cargo_description": d.cargo_description,
            "action": 's',
            "action_id": d.action_id,
            "order": d.order,
            "date": d.date,
            "extra_notes": d.extra_notes

        }))
        let sortActions = options.sort((a, b) => a.order - b.order)
        //Changed from options to sortActions to sort by order otherwise it gets sorted by date.
        this.setState({ shippers: sortActions })
    }
    async getReceivers() {
        const { load_id } = this.props.match.params
        const result = await axios.get(`https://fltbd.herokuapp.com/actions_receivers/${load_id}`)
        const receiverData = result.data

        const options = receiverData.map(d => ({

            "location_name": d.location_name,
            "street_address": d.street_address,
            "city": d.city,
            "state": d.state,
            "contact_name": d.contact_name,
            "zipcode": d.zipcode,
            "phone_number": d.phone_number,
            "pickup_number": d.pickup_number,
            "cargo_description": d.cargo_description,
            "action": d.action,
            "action_id": d.action_id,
            "order": d.order,
            "date": d.date,
            "extra_notes": d.extra_notes

        }))
        let sortActions = options.sort((a, b) => a.order - b.order)
        this.setState({ receivers: sortActions })

    }
    async getLoadDetails() {
        const { load_id } = this.props.match.params
        const result = await axios.get(`https://fltbd.herokuapp.com/document/confirmation/load/${load_id}`)
            .catch(error => {
                if (error.response.status === 404) {
                    this.setState({ notFound: true })
                    this.setState({ load_id: load_id })
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    return
                }
                else {
                    
                }
               
            });
        // below will not work if rate confirmation is not created.
       
        if(!result) {
            this.setState({ notFound: true })
        }
        else {
            const loadData = result.data
            this.setState(loadData)
        }
       
       
    }



    generatePDF() {
        setTimeout(() => {
            this.getLoadDetails();
            this.setState({ isGenerating: false })
        }, 5000);
        return (
            <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-center">
                            <h3>Generating  your PDF File</h3> <br />
                        </div>
                        <br />
                        <div class="d-flex justify-content-center">
                            <div>Your file will be finished in 5 seconds...</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    generatedPDF() {
        const { load_id } = this.props.match.params

        const document = `https://fltbd-bucket.s3.us-east-2.amazonaws.com/${load_id}.pdf`
        return (
            <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Completed Rate Confirmation</h3>
                    </div>
                    <div class="card-body">
                        <p class="card-title">If you can't view the rate confirmation below DOWNLOAD <a href={document} target="_blank">HERE</a></p>
                        <embed width="100%" height="600px" src={document}></embed>
                    </div>
                </div>

            </div>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.load_id !== this.state.load_id) {
            this.getLoadDetails();
            
        }



    }
    componentDidMount() {
        setTimeout(() => {
            this.getShippers();
            this.getReceivers();

        }, 1000)


    }
    render() {
        if (this.state.status === "Accepted") {
            return (
                this.generatedPDF()
            )
        }
        if (this.state.notFound === true) {
            return (
                <Styles>
                    <div class="container-fluid not-found-container">
                        <div class="d-flex justify-content-center">
                            <h2>This Document Does Not Exist</h2>

                        </div>
                        <div class="d-flex justify-content-center">
                            <h3>Please contact the broker regarding this</h3>
                        </div>
                    </div>
                </Styles>
            )
        }
        return (
            this.state.isGenerating ? this.generatePDF() :
                <RateConfirmationItem {...this.state} parentCallbackGenerating={this.handleCallbackGenerating} />

        )
    }
}


export default withRouter(RateConfirmationContainer)
