import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import LoadsDetails from './LoadsDetails'
import LoadsCarrierDetails from './LoadsCarrierDetails'
import LoadsSummary from './LoadsSummary'
import LoadsShippers from './LoadsShippers'
import LoadsReceivers from './LoadsReceivers'

import LoadsFinances from './LoadsFinances'
import LoadsCustomer from './LoadsCustomer'
import LoadsConfirmRC from "./Modals/LoadsConfirmRC";
import { authFetch } from '../../auth'

import { format, parseISO } from 'date-fns';
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../Modals/LoadingModal";





export default function LoadsItem(props) {
    const params = useParams();
    const [data, setData] = useState()
    const [loadNumber, setLoadNumber] = useState(" ")
    const [accepted, setAccepted] = useState()
    const [notFound, setNotFound] = useState(false)
    const [carriers, setCarriers] = useState([])
    const [carrierName, setCarrierName] = useState()
    const [doubleBroker, setDoubleBroker] = useState(false)
    const [carrierInfo, setCarrierInfo] = useState()
    const [loads, setLoads] = useState()

    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const history = useHistory();

    const handleLoadDetails = (Details) => {
        const detailKey = Object.keys(Details)[0]
        const detailValue = Object.values(Details)[0]
        
        
        if(detailKey === "carrier_pay" || detailKey === "gross_pay") {
            const mappedData = loads.map(g => (g.load_id === params.load_id ? Object.assign({}, g, {
                [detailKey]: parseInt(detailValue)
            }) : g))
            setData({ ...data, [detailKey]: parseInt(detailValue) })
            props.updateLoadList(mappedData)
            
        }
        else{
            const mappedData = loads.map(g => (g.load_id === params.load_id ? Object.assign({}, g, {
                [detailKey]: detailValue
            }) : g))
            setData({ ...data, [detailKey]: detailValue })
            props.updateLoadList(mappedData)
        }
        
    }
    const handleEquipmentDetails = (Equipment) => {

        setData({ ...data, equipment: Equipment })
        const mappedData = loads.map(g => (g.load_id === params.load_id ? Object.assign({}, g, {
            equipment: Equipment
        }) : g))
        props.updateLoadList(mappedData)


    }

    //Testing on changing actions instead of using shippers+actions
    //This is working so far
    const handleActions = (e, actionID) => {
        const updateAction = data.actions.map((action) => {
            if (actionID !== action.action_id) {
                return action;
            }
            return { ...action, [e.target.name]: e.target.value }
        })
        setData({ ...data, actions: updateAction })
        const mappedData = loads.map(g => (g.load_id === params.load_id ? Object.assign({}, g, {
            actions: updateAction
        }) : g))
        props.updateLoadList(mappedData)
        
    }

    //This will Handle Adding Actions from LoadShippers.js
    const handleAddAction = (Action) => {
        if (data && data.actions) {
            setData({ ...data, actions: [...data.actions, Action] })
        }
        const mappedData = loads.map(g => (g.load_id === params.load_id ? Object.assign({}, g, {
            actions: [...data.actions, Action]
        }) : g))
        props.updateLoadList(mappedData)
        
    }

    const handleRemoveAction = (Action) => {
        const removeAction = data.actions.filter((a) => Action.action_id !== a.action_id)
        setData({ ...data, actions: removeAction })
        const mappedData = loads.map(g => (g.load_id === params.load_id ? Object.assign({}, g, {
            actions: removeAction
        }) : g))
        props.updateLoadList(mappedData)
    }
    const handleActionChange = (Action, ActionID) => {
        const changeAction = data.actions.map((a) => {

            if (ActionID !== a.action_id) {
                return a;
            }
            return {
                ...a,
                location_name: Action.location_name,
                street_address: Action.street_address,
                city: Action.city,
                state: Action.state,
                zipcode: Action.zipcode,
                contact_name: Action.contact_name,
                phone_number: Action.phone_number,
                pickup_number: Action.pickup_number,
                cargo_description: Action.cargo_description,
                extra_notes: Action.extra_notes,
            };

        })
        setData({ ...data, actions: changeAction }) 
        const mappedData = loads.map(g => (g.load_id === params.load_id ? Object.assign({}, g, {
            actions: changeAction
        }) : g))
        props.updateLoadList(mappedData)
    }
    const handleActionOrder2 = (index, e) => {
        if (e.currentTarget.name === 'moveUp') {
            //const actionsInfo = props.actions.filter(action => action.action == "s")
            const newItems = [...data.actions];
            const itemToMove = newItems.splice(index, 1);
            newItems.splice(index - 1, 0, itemToMove[0]);


            setData({ ...data, actions: newItems })
        }
        if (e.currentTarget.name === 'moveDown') {
            //const actionsInfo = props.actions.filter(action => action.action == "s")
            const newItems = [...data.actions];
            const itemToMove = newItems.splice(index, 1);
            newItems.splice(index + 1, 0, itemToMove[0]);
            setData({ ...data, actions: newItems })
        }
    }

    const handleActionOrder = (Action, e) => {



        if (e.currentTarget.name === 'upArrow') {
            const updateAction = data.actions.map((action) => {
                if (Action.action_id !== action.action_id) {
                    return { ...action, order: parseInt(action.order) + 1 }
                }

                return { ...action, order: parseInt(action.order) - 1 }
            })
            setData({ ...data, actions: updateAction })
        }
        if (e.currentTarget.name === 'downArrow') {
            const updateAction = data.actions.map((action) => {
                if (Action.action_id !== action.action_id) {
                    return { ...action, order: parseInt(action.order) - 1 }
                }

                return { ...action, order: parseInt(action.order) + 1 }
            })
            setData({ ...data, actions: updateAction })
        }


    }

    // handleShippers & handleReceivers will soon be overhauled & replaced with the above action changes
    const handleShippers = (Shippers) => {
        setData({ ...data, shippers: Shippers })
    }
    const handleReceivers = (Receivers) => {
        setData({ ...data, receviers: Receivers })
    }
    const handleCarrierDetails = (CarrierDetails) => {

        setData({
            ...data,
            carrier_name: CarrierDetails.carrier_name,
            carrier_dot: CarrierDetails.carrier_dot,
            carrier_mc: CarrierDetails.carrier_mc,
            carrier_match: CarrierDetails.carrier_match,
            do_not_load: CarrierDetails.do_not_load

        })
        setCarrierName(CarrierDetails.carrier_name)
        setCarrierInfo(CarrierDetails)
        //const dataCopy = [...props.loads]
        const mappedData = loads.map(g => (g.load_id === params.load_id ? Object.assign({}, g, {
            carrier_name: CarrierDetails.carrier_name,
            carrier_dot: CarrierDetails.carrier_dot,
            carrier_mc: CarrierDetails.carrier_mc,
            carrier_match: CarrierDetails.carrier_match,
            do_not_load: CarrierDetails.do_not_load
        }) : g))
        props.updateLoadList(mappedData)
    }
    const dbCheck = (CarrierMC) => {
        const check = props.db.filter(z => z.mc_number == CarrierMC)
        if (props && props.db && check.length > 0) {
            setDoubleBroker(true)
        }
        else {
            setDoubleBroker(false)
        }
    }



    useEffect(() => {
        if (props && props.loads) {
            const loadInfo = props.loads.filter(load => load.load_id == params.load_id)
            setData(loadInfo[0])
            if (loadInfo[0] && loadInfo[0].load_number) {
                setLoadNumber(loadInfo[0].load_number)

            }
            if (loadInfo[0] && loadInfo[0].accepted) {
                setAccepted(loadInfo[0].accepted)
            }
            if (loadInfo[0] && loadInfo[0].carrier_name) {
                setCarrierName(loadInfo[0].carrier_name)
                const carriers = props.carriers.filter(z => z.id == loadInfo[0].carrier_match)

                if (carriers === null || carriers.length > 0) {
                    // Testing commenting out below seems to be working?
                    //handleCarrierDetails(carriers[0])
                    setCarrierInfo({
                        carrier_mc: carriers[0].carrier_mc,
                        carrier_dot: carriers[0].carrier_dot,

                    })
                }
            }

        }

    }, [props.loads])



    useEffect(() => {
        if (props && props.carriers) {
            setCarriers(props.carriers)
        }
    }, [props.carriers])

    useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);

    useEffect(() => {
        if(props && props.loads) {
            setLoads(props.loads)
        }
    },[props.loads])




    return (
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            {notFound === true ?
                <div class="card">
                    <center>No Load Found</center>
                </div> :
                <div class="d-sm-flex align-items-center justify-content-between">
                    <h1>Load #{loadNumber}</h1>

                    <div>
                        <LoadsConfirmRC {...props} carrierName={carrierName} data={data} accepted={accepted} loadNumber={loadNumber} />
                    </div>


                </div>
            }
            {notFound === true ? null :
                <div class="row row-deck">
                    <div class="col-sm-6 col-lg-4">
                        <LoadsDetails {...data}
                            parentEquipmentDetails={handleEquipmentDetails}
                            parentLoadDetails={handleLoadDetails} />
                    </div>
                    <div class="col-sm-6 col-lg-4">
                        <LoadsCarrierDetails {...data} carriers={carriers}
                            parentCarrierDetails={handleCarrierDetails}
                            loadID={params.load_id}
                            dbCheck={dbCheck}
                            doubleBroker={doubleBroker}
                            {...carrierInfo}
                        />
                    </div>
                    <div class="col-sm-6 col-lg-4" style={{ display: "inline" }}>
                        <LoadsCustomer {...props} {...data} parentLoadDetails={handleLoadDetails} />
                        <LoadsFinances {...props} {...data} parentLoadDetails={handleLoadDetails} />
                    </div>
                </div>
            }
            {notFound === true ? null :
                <div class="row">
                    <div class='col-md-12'>
                        <div class="row">
                            <div class='col-md-6'>
                                <LoadsShippers
                                    {...props} {...data}
                                    parentAddAction={handleAddAction}
                                    parentRemoveAction={handleRemoveAction}
                                    parentActions={handleActions}
                                    parentActionChange={handleActionChange}
                                    parentActionOrder={handleActionOrder}
                                    parentActionOrder2={handleActionOrder2}
                                    parentShippers={handleShippers}
                                    loadID={params.load_id} />

                            </div>
                            <div class='col-md-6'>
                                <LoadsReceivers {...props} {...data}
                                    parentAddAction={handleAddAction}
                                    parentRemoveAction={handleRemoveAction}
                                    parentActions={handleActions}
                                    parentActionChange={handleActionChange}
                                    parentActionOrder={handleActionOrder}
                                    parentActionOrder2={handleActionOrder2}
                                    parentReceivers={handleReceivers}
                                    loadID={params.load_id} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )



}