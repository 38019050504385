import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import {
    OverlayTrigger,
    Tooltip,
    Modal,
    Button
} from "react-bootstrap";
import { authFetch } from '../../../auth';
import { v4 as uuidv4 } from "uuid"


const autoSendToolTip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If this option is selected, we will send the rate confirmation to the carrier's email on file via our emailing system.
    </Tooltip>
);

const hideToolTip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If this option is selected, the shippers & receivers details will be hidden until the carrier signs the rate confirmation.
    </Tooltip>
);

const LoadsConfirmRC = (props) => {

    const initialState = () => {
        return (props)
    }
    const history = useHistory();

    const [state, setState] = useState(initialState);
    const [showModal, setShow] = useState(false);
    const [hideDetails, setHideDetails] = useState()
    const [checked, setChecked] = useState(false)
    const [formIsValid, setFormIsValid] = useState(true)
    const [errors, setErrors] = useState({
        carrier: '',
        trailer: '',
        equipment: '',
        gross_pay: '',
        carrier_pay: '',
      });


    const handleChecked = () => {
        setChecked(!checked);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setState(props);
    }

    const handleHideDetails = (e) => {
        setHideDetails(!hideDetails)
    }

    const currentRateConfirmation = (e) => {
        e.preventDefault();
        history.push(`/documents/rateconfirmation/${props.data.load_id}`)
    }

    const createRateConfirmation = (e) => {
        //handleValidation(props) in here somewhere, if success then you can do it.
        e.preventDefault();
        const uniqID = uuidv4()
        authFetch(`https://fltbd.herokuapp.com/document/confirmation/load/${props.data.load_id}`, {
            method: 'DELETE'
        })
        authFetch('https://fltbd.herokuapp.com/documents/confirmation', {
            method: 'PUT',
            body: JSON.stringify({
                binding_id: uniqID,
                load_id: props.data.load_id,
                load_number: props.data.load_number,
                trailer: props.data.trailer,
                equipment: [props.data.equipment],
                carrier_name: props.data.carrier_name,
                carrier_pay: props.data.carrier_pay,
                carrier_id: props.data.carrier_match,
                hide_details: hideDetails,
                auto_send: checked.toString()
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        const dataCopy = [...props.loads]
        const mappedData = dataCopy.map(g => (g.load_id === props.data.load_id ? Object.assign({}, g, { 
                binding_id: uniqID,
                load_id: props.data.load_id,
                load_number: props.data.load_number,
                trailer: props.data.trailer,
                equipment: [props.data.equipment],
                carrier_name: props.data.carrier_name,
                carrier_pay: props.data.carrier_pay,
                gross_pay: props.data.gross_pay,
                actions: props.data.actions,

         }) : g))
        props.updateLoadList(mappedData)

        history.push(`/documents/rateconfirmation/${props.data.load_id}`)
    }
    
    //Validation is not working and might not be worth setting up yet.
    const handleValidation = (props) => {
        const newErrorState = {...errors}
        let formIsValid = true
        console.log(props)
        if(props.data.carrier_name === "Not Assigned") {
            formIsValid = false
            newErrorState.carrier = "You must select a carrier"
        }
        if(props.data.trailer === null) {
            formIsValid = false
            newErrorState.trailer = "You must select a trailer type"
        }
        if (!formIsValid) { // if any field is invalid - then we need to update our state
            setFormIsValid(false);
            setErrors(newErrorState);
        }

        return formIsValid

    }

    useEffect(() => {
        if(props && props.data) {
            handleValidation(props)
        }
    }, [props.data])
    

    return (
        <>
       
       {props.carrierName === "Not Assigned"
            
            ? null :
                <Link>
                    <Button className="btn btn-success" onClick={handleShow}>
                        Create Rate Confirmation
                    </Button>
                </Link>
            }
      
            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}


            >

                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                {props.accepted == true ?
                    <form>
                        <Modal.Body class="modal-body">
                            <div>This rate confirmation has already been signed by the carrier.
                                Would you like to overwrite the existing rate confirmation? <br />
                                Please select your option below:<br />
                            </div>
                            <br />
                            <div></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleClose}>
                                Go Back
                            </Button>
                            <Button variant="success" onClick={currentRateConfirmation}>
                                Current Rate Confirmation
                            </Button>
                            <Button variant="danger" onClick={createRateConfirmation} >
                                Overwrite Existing Rate Confirmation
                            </Button>
                        </Modal.Footer>
                    </form>
                    :
                    <>
                        <Modal.Body class="modal-body">
                            <div>You are about to create a rate confirmation for Load #{props.loadNumber} </div>
                            <br />
                            <div class="form-check">
                                <input class="form-check-input" id="hideDetailsCheck" type="checkbox" checked={hideDetails} onChange={handleHideDetails} />
                                <label class="form-check-label" for="hideDetailsCheck">
                                    Hide Details
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={hideToolTip}
                                    >
                                        <small> [?]</small>
                                    </OverlayTrigger>
                                
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="sendToCarrier" checked={checked} name="autosend" onChange={handleChecked} />
                                <label class="form-check-label" for="sendToCarrier">
                                    Auto-Send To Carrier
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={autoSendToolTip}
                                    >
                                        <small> [?]</small>
                                    </OverlayTrigger>
                                </label>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleClose}>
                                Go Back
                            </Button>
                            <Button variant="success" onClick={createRateConfirmation} >
                                Create Rate Confirmation
                            </Button>
                        </Modal.Footer>
                    </>
                }
            </Modal>


        </>
    )
}

export default LoadsConfirmRC