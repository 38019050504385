import React, { Component } from 'react'
import {
  Link,
} from "react-router-dom";

export class Footer extends Component {
  render() {
    return (
      <div id="footer">
        <div class="container mt-3">

          <div class="row">
            <div className="col-md-auto">
              <h3 class="font-color-white">fltbd</h3>
            </div>
            
            <div className="col-md-auto">
              <Link to="/privacy-policy">
              <p class="font-color-white" style={{ marginTop: "0.15rem" }}>Privacy Policy</p>
              </Link>
            </div>
            <div className="col-md-auto">
              <Link to="/terms-conditions">

              <p class="font-color-white" style={{ marginTop: "0.15rem" }}>Terms & Conditions</p>
            </Link>
            </div>
            {3===2 ?
            <>
            <div className="col-md-auto">
              <p class="font-color-white" style={{ marginTop: "0.15rem" }}>Resources</p>
            </div>
            <div className="col-md-auto">
              <p class="font-color-white" style={{ marginTop: "0.15rem" }}>Contact</p>
            </div>
            </>
            :
            null}
            
          </div>

        </div>
      </div>

    )
  }
}

export default Footer