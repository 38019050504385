import React, { Component, useEffect, useState } from "react"
import LoadsHeader from "./LoadsHeader"
import {
    Route, Switch, Redirect, Router
} from 'react-router-dom';
import LoadsTable from "./LoadsTable";
import { Button, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import OnboardModal from "../Modals/OnBoardModal";
import LoadingModal from "../Modals/LoadingModal";



const Styles = styled.div`
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
background-color: #7E6651;
color: #fff;
}
.navbar-dark .navbar-nav .nav-link a:hover {
    color: rgba(255,255,255,.7);
}
.nav-link.hover {
    color: black;
}

a {
    color: white;
}
a:hover {
    color: white;
}

`
const ButtonLegend = () => {

    return (
        <>
        <button className="btn btn-sm btn-primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="2"></circle><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>View</button>
        <button class="btn btn-sm btn-green"> 
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" /><path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" /><line x1="16" y1="5" x2="19" y2="8" /></svg>Edit</button>
            <button class="btn btn-sm btn-info" >
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" /><line x1="8" y1="8" x2="12" y2="8" /><line x1="8" y1="12" x2="12" y2="12" /><line x1="8" y1="16" x2="12" y2="16" /></svg>Documents</button>
            <button className="btn btn-sm btn-danger">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="4" y1="7" x2="20" y2="7" /><line x1="10" y1="11" x2="10" y2="17" /><line x1="14" y1="11" x2="14" y2="17" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>Delete</button>
        </>
    )
}

export default function LoadsContainer(props) {
    
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(true);
    const [allLoads, setAllLoads] = useState([])
    const [activeLoads, setActiveLoads] = useState([])
    const [billingReady, setBillingReady] = useState([])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);


    useEffect(() => {
        if (props && props.loads) {
            const loads = props.loads
            setAllLoads(loads)
            const active = props.loads.filter(load => 
                load.status !== "Completed" &&
                load.status !== "Invoiced" &&
                load.status !== "Paid" ) 
            setActiveLoads(active)
            const readyForBilling = props.loads.filter(load => load.status == "Completed")
            setBillingReady(readyForBilling)
            setShow(false)
            setLoading(false)
            
        }
    }, [props.loads])
    
    const updateLoads = (List, Tab) => {
        // not being used
        // this does work by giving the tabs props
        if(Tab == "activeLoads") {
            const active = List.filter(load => load.status !== "Completed")
            setActiveLoads(active)
        }
        if(Tab == "billingReady") {
            const readyForBilling = List.filter(load => load.status == "Completed")
            setBillingReady(readyForBilling)
        }
        if(Tab == "allLoads") {
            setAllLoads(List)
        }
    }
    const updateSingle = (row, e) => {
        const dataCopy = [...props.loads]
        const mappedData = dataCopy.map(g => (g.load_id === row.row.values.load_id ? Object.assign({}, g, { status: e.value }) : g))

        props.updateLoadList(mappedData)
    }
    const patchUpdate = (patchLoads, e) => {
        const toPatch = new Set(patchLoads);
        const dataCopy = [...props.loads]
        const mappedData = dataCopy.map(g => (toPatch.has(g.load_id) ? Object.assign({}, g, { status: e.value }) : g))
        props.updateLoadList(mappedData)
      }
    const deleteLoads = (e) => {
        props.updateLoadList(e)
    }


    return (
        <>
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            {props.onBoard ? <OnboardModal {...props} /> : null }
            <LoadsHeader updateLoadList={props.updateLoadList} {...props} />
            <Styles>
            <Tabs defaultActiveKey="activeLoads" className="navbar navbar-expand-md navbar-dark navbar-overlap d-print-none" style={{borderBottom: "0"}} transition={false}>
                <Tab eventKey="activeLoads"  title="Active">
                    <LoadsTable {...props} data={activeLoads} 
                        updateLoads={updateLoads} 
                        tab="activeLoads" 
                        updateSingle={updateSingle} 
                        patchUpdate={patchUpdate}
                        deleteLoads={deleteLoads}
                        />
                </Tab>
                <Tab eventKey="billingReady" title="Ready For Billing">
                    <LoadsTable {...props} data={billingReady} updateLoads={updateLoads} tab="billingReady" updateSingle={updateSingle} patchUpdate={patchUpdate} />
                </Tab>
                <Tab eventKey="allLoads" title="All Loads">
                    <LoadsTable {...props} data={allLoads} updateLoads={updateLoads} tab="allLoads" updateSingle={updateSingle} patchUpdate={patchUpdate}/>
                </Tab>
            </Tabs>
            </Styles>
            <div class="mt-3 font-color-white">
            
            <h3>Legend: <br /><ButtonLegend /></h3>
            </div>

        </div>
        <Modal show={show} size="sm" centered>

               <LoadingModal />

            </Modal>
        </>
    )
}
