import React, { useEffect, useState } from "react";
import styled from "styled-components"
import Footer from "../Footer";
import HomeMasthead from "./HomeMasthead";
import HomeJumbotron from "./HomeJumbotron";
import fltbd_rc_mobile_phone_1 from "../../assets/img/fltbd_rc_mobile_phone_1.png"
import fltbd_rc_mobile_phone_2 from "../../assets/img/fltbd_rc_mobile_phone_2.png"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter,
} from "react-router-dom";


export default function HomeContainer() {
  return (
    <>
    
      <section class="jumbotron text-center">
        <div class="container">


          <h1 class="jumbotron-header">A TMS That Delivers.</h1>
          <p class="jumbotron-lead font-color-white">fltbd was created out of necessity, you can level the playing field and achieve success with its straightforward and user-friendly features.</p>
          <p>

            <Link to="/signup"><button class="btn btn-success m-2">Get Started For Free</button></Link>
          </p>
        </div>
      </section>
      <div style={{ backgroundColor: "none" }}>
        <div class="build-loads-container">
          <div class="row">
            <div class="col-md-7">
              <h2 className="font-color-white">Simply Simple.</h2>
              <p class="lead font-color-white">Eliminating unnecessary features, or "bloat," is crucial in achieving efficiency and simplicity.</p>
              <h2 className="font-color-white">Build Loads. Build Success.</h2>
              <p class="lead font-color-white">Easily create rate confirmations and streamline your operations with minimal clicks.</p>
              <h2 className="font-color-white">Your Contract. Your Conditions.</h2>
              <p class="lead font-color-white">Customize your experience with us by using your own contract and setting your own terms and conditions.</p>
              <h2 className="font-color-white ">Send Electonically. </h2>
              <p class="lead font-color-white">Send your documents via email directly from the app, example email below:</p>

              <div className="card" style={{ marginTop: "0rem" }}>
                <div className="card-body" style={{ padding: "1.5rem" }}>
                  You have received a rate confirmation from: <br /> <b>Jordan Brokers Inc.</b>
                  <div className="d-flex justify-content-center" style={{ padding: "1.5rem", textAlign: "center" }}>
                    <a href="/rateconfirmation/demo" target="_blank">
                      <button className="btn btn-primary">View Rate Confirmation</button>
                    </a>
                  </div>
                  <div class="d-none d-sm-block" style={{ borderTop: "1px solid #EAEAEC", marginTop: "15px" }}>
   
                      <div style={{ paddingTop: "25px" }}>
                        <p class="text-muted">If you’re having trouble with the button above, copy and paste the URL below into your web browser.</p>
                        <p class="text-muted d"> https://www.fltbd.com/rateconfirmation/demo</p>
                      </div>
                  
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-5 d-flex justify-content-center d-none d-sm-block">
              <img src={fltbd_rc_mobile_phone_2} />
            </div>
          </div>
        </div>


      </div>



    </>


  )

}