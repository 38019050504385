import React, { useEffect, useState } from "react";
import { useAuth, authFetch, logout } from "../../auth";
import SettingsConfirmation from "./SettingsConfirmation";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link,
    useHistory,
    useRouteMatch,
    useParams
} from "react-router-dom";
import CompanyContract from "./SettingsContract";
import SettingsSidebar from "./SettingsSidebar";
import ChangePassword from "./SettingsPassword";
import ManageUsers from "./SettingsManageUsers";
import SettingsContract from "./SettingsContract";
import SettingsPassword from "./SettingsPassword";
import SettingsManageUsers from "./SettingsManageUsers";
import SettingsUserItem from "./SettingsUserItem";
import SettingsAddUser from "./SettingsAddUser";
import SettingsUploadLocations from "./SettingsUploadLocations";
import SettingsUploadCustomers from "./SettingsUploadCustomers";
import SettingsUploadCarriers from "./SettingsUploadCarriers";
import SettingsLoadNumber from "./SettingsLoadNumber";
import SettingsSubscription from "./SettingsSubscription";
import io from "socket.io-client"
import SettingsCompanyInformation from "./SettingsCompanyInformation";
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../Modals/LoadingModal";

function SettingsHeader() {
    return (
        <div class="d-sm-flex">
            <h1>Settings</h1>
        </div>
    )
}

const ENDPOINT = "https://fltbd.herokuapp.com";


export default function SettingsContainer(props) {

    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(true)
    const [userRole, setUserRole] = useState('')
    const [employees, setEmployees] = useState()
    const [stripe, setStripe] = useState()
    const [email, setEmail] = useState()
    const [status, setStatus] = useState()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        const socket = io.connect(ENDPOINT); //{ transports:["websocket"]}
        console.log("connected", socket);
        socket.on("connect", (data) => console.log(data));
        
        socket.on("test_room", socket => {
            //Not working
            socket.join("company");
            console.log("test")
          });

        return (() => {
            socket.disconnect()
        })

    }, []);
      useEffect(() => {
        const socket = io.connect(ENDPOINT);
        console.log("testing the stripe connection")
        socket.on("handle_stripe", (data) => console.log(data))
        socket.on("handle_stripe", (data) => handleStatus(data))
        return (() => {
            socket.disconnect()
        })
  }, [])

    const handleStatus = (data) => {
        if(data.Metadata.company_id !== props.id) {
            //props.id is coming undefined.

            setStatus(data.Metadata.contract_id)
        }
        else {

            setStatus(data.Metadata.contract_id)
        }
    }



    const updateList = (List) => {
        const dataCopy = [...props.employees]
        props.updateEmployeeList([...dataCopy, List])
    }
    const deleteEmployee = (Employee) => {
        const dataCopy = [...props.employees]
        const removeEmployee = dataCopy.filter((a) => Employee !== a.user_id)
        props.updateEmployeeList(removeEmployee)
    }

    useEffect(() => {
        if (props && props.roles) {
            setUserRole(props.roles)
        }
    }, [props.roles])
    useEffect(() => {
        if (props && props.employees) {
            setEmployees(props.employees)
            setShow(false)
            setLoading(false)
        }
    }, [props.employees])
    useEffect(() => {
        if (props && props.stripe) {
            setStripe(props.stripe)
        }
    }, [props.stripe])
    useEffect(() => {
        if (props && props.email) {
            setEmail(props.email)
        }
    }, [props.email])

    useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);

    return (
        <>
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <Router history={history}>
                <SettingsHeader />
                <div class="row row-cards" style={{ paddingTop: '0px' }}>
                    <SettingsSidebar  {...props} />
                    <div class="col-lg-9">
                            {status}
                        <Switch>
                            <Route path="/settings/subscription"
                                render={(props) => <SettingsSubscription email={email} stripe={stripe} {...props} userRole={userRole} employees={employees} title="fltbd - Subscription" />} />

                            <Route path="/settings/manage-users"
                                render={() => <SettingsManageUsers  {...props} userRole={userRole} employees={employees} title="fltbd - Manage Users" />} />

                            <Route path="/settings/manage-user/add"
                                render={(props) => <SettingsAddUser updateList={updateList} {...props} userRole={userRole} employees={employees} title="fltbd - Add User" />} />

                            <Route path="/settings/manage-user/:user_id"
                                render={(props) => <SettingsUserItem deleteEmployee={deleteEmployee} {...props} userRole={userRole} employees={employees} />} />

                            <Route path="/settings/change-password"
                                render={(props) => <SettingsPassword {...props} userRole={userRole} employees={employees} title="fltbd - Change Password" />} />


                            <Route path="/settings/company-information">
                                <SettingsCompanyInformation {...props} userRole={userRole} title="fltbd - Company Information" />
                            </Route>
                            <Route path="/settings/contract-settings">
                                <SettingsContract {...props} userRole={userRole} title="fltbd - Contract Settings" />
                            </Route>


                            <Route path="/settings/confirmation-settings">
                                <SettingsConfirmation {...props} userRole={userRole} title="fltbd - Confirmation Settings" />
                            </Route>

                            <Route path="/settings/load-number-settings">
                                <SettingsLoadNumber {...props} userRole={userRole} title="fltbd - Load Number Settings" />
                            </Route>

                            <Route path="/settings/upload-locations"
                                render={(props) => <SettingsUploadLocations {...props} userRole={userRole} />} />

                            <Route path="/settings/upload-carriers">
                                <SettingsUploadCarriers {...props} userRole={userRole} />
                            </Route>

                            <Route path="/settings/upload-customers">
                                <SettingsUploadCustomers {...props} userRole={userRole} />
                            </Route>

                            <Route path="*">

                                <Redirect to='/settings/change-password' />
                            </Route>

                        </Switch>



                    </div>
                </div>
            </Router>

        </div>
        <Modal show={show} size="sm" centered>

               <LoadingModal />

            </Modal>
        </>

    )
}