import React, { useMemo, useState, useEffect } from "react";
import reactDom from "react-dom";
import CustomersTable from './CustomersTable'
import CustomersHeader from './CustomersHeader'
import delCustomer from './CustomersTable'

import axios from 'axios'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter,
  useHistory
} from "react-router-dom";
import { useAuth, authFetch, logout } from "../../auth"
import CustomersField from "./CustomersField";



function CustomersContainer(props) {
    const [customers, setCustomers] = useState([])

    const handleNewCustomer = (NewCustomer) => {
        const dataCopy = [...customers]
        setCustomers([...dataCopy, NewCustomer])
        props.updateCustomersList([...dataCopy, NewCustomer])
    }
    
    

      useEffect(() => {
        if(props  &&  props.customers) {
            setCustomers(props.customers)
        }
    }, [props.customers])
    
    useEffect(() => {
        document.title = props.title || "";
      }, [props.title]);
    
    return (
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <CustomersHeader />
            <CustomersField parentNewCustomer={handleNewCustomer} />
            <CustomersTable customersData={customers} />
        </div>
    )
}


export default CustomersContainer