import React, { useEffect, useState } from "react";
import { authFetch } from "../../auth";

const CompanyInformationLoading = () => {
    return (
        <>
            <div className="card">
                <div class="card-header">
                    <h3 class="card-title">Company Information</h3>

                </div>
                <div class="card-body">

                    <div class="form-row">
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Name</label>
                            <div class="placeholder-glow"><span class="placeholder col-6 placeholder"></span></div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Phone</label>
                            <div class="placeholder-glow"><span class="placeholder col-6 placeholder"></span></div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6 mb-3">
                            <label class="form-label">MC</label>
                            <div class="placeholder-glow"><span class="placeholder col-6 placeholder"></span></div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">DOT</label>
                            <div class="placeholder-glow"><span class="placeholder col-6 placeholder"></span></div>
                        </div>
                    </div>
                    <div class="form-row">
                    <div class="col-md-12 mb-3">
                        <label class="form-label">Address</label>
                        <div class="placeholder-glow"><span class="placeholder col-12 placeholder"></span></div>
                    </div>
                    </div>

                </div>

            </div>
        </>

    )
}

export default function SettingsCompanyInformation(props) {

    const [loadNumber, setLoadNumber] = useState()
    const [loading, setLoading] = useState(true)

    const handleSubmit = (e) => {
        e.preventDefault()
        authFetch(`https://fltbd.herokuapp.com/api/companys/${props.id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                current_load_number: loadNumber,
            }),
            headers: { 'Content-Type': 'application/json' },
        })

    }
    const handleChange = e => {

    }
    useEffect(() => {
        if (props && props.company_name) {
            setLoading(false)
        }

    }, [props.company_name])

    if (loading) {
        return (
            <CompanyInformationLoading />
        )
    }

    if (!props.userRole.includes("admin")) {
        return <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Company Information</h3>
            </div>
            <div class="card-body">

                You don't have access to this. If you think this a mistake please contact one of your company's administators.
            </div>
        </div>

    }

    return (
        <div className="card">
            <div class="card-header">
                <h3 class="card-title">Company Information</h3>

            </div>
            <div class="card-body">

                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Name</label>
                        <input type="text" class="form-control" value={props.company_name} name="company_name" disabled={true} />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Phone</label>
                        <input type="text" class="form-control" value={props.phone_number} name="phone_number" disabled={true} />
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label class="form-label">MC</label>
                        <input type="text" class="form-control" value={props.mc_number} name="mc_number" disabled={true} />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label">DOT</label>
                        <input type="text" class="form-control" value={props.dot_number} name="mc_number" disabled={true} />
                    </div>
                </div>
                <div class="form-row">
                    <label class="form-label">Address</label>
                    <textarea class="form-control" value={props.company_address} name="carrier_address" disabled={true} />

                </div>

            </div>

        </div>
    )

}

