import React, { useEffect, useState, Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    withRouter,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { authFetch } from "../../auth";
import { Spinner, Button } from "react-bootstrap";


export default function CarriersField(props) {

    const [newCarrier, setNewCarrier] = useState('')
    const [collapse, setCollapse] = useState(true)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [checked, setChecked] = useState(false)
    const handleChange = e => {
        setNewCarrier({
            ...newCarrier,
            [e.target.name]: e.target.value
        })
    }
    const handleChecked = () => {
        setChecked(!checked);
    };

    const handleSubmit = e => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(newCarrier.carrier_email)) {
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
            const uniqueID = uuidv4()

            authFetch(`https://fltbd.herokuapp.com/create_new_contract`, {
                method: 'PUT',
                body: JSON.stringify({
                    contract_id: uniqueID,
                    carrier_email: newCarrier.carrier_email,
                    auto_send: checked.toString()
                }),
                headers: { 'Content-Type': 'application/json' },
            })
            props.parentNewPendingCarrier({
                contract_id: uniqueID,
                carrier_email: newCarrier.carrier_email,
                status: 'Not Started'
            })
            setLoading(true);
            setTimeout(() => {
                setNewCarrier({
                    contract_id: '',
                    carrier_email: '',
                    status: '',
                })
                setLoading(false);
            }, 500);
            setError("")

        }
        else {
            setError('Not A Valid Email.')
            // invalid email, maybe show an error to the user.
        }
        e.preventDefault();

    }
    return (
        <div class="card" >
            <div class="card-header" data-toggle="collapse"
                data-target="#addCarrier" onClick={() => setCollapse(!collapse)} style={{ cursor: "pointer" }}>
                <h3 class="card-title">Add New Carrier</h3>
                <div class="edit-button">
                    {collapse ?
                        <button class="btn btn-success" type="button" data-toggle="collapse" data-target="#addCarrier" aria-expanded="false" onClick={() => setCollapse(false)}>
                            +
                        </button>
                        :
                        <button class="btn btn-danger" type="button" data-toggle="collapse" data-target="#addCarrier" aria-expanded="false" onClick={() => setCollapse(true)}>
                            -
                        </button>
                    }
                </div>
            </div>
            <div class="card-body collapse" id="addCarrier">
                <form onSubmit={handleSubmit}>
                    <div class="form-group">
                        <div class="row">
                        
                            <label for="inputCustomerName" class="form-label">Carrier Email</label>
                            <div class="col-md-12 d-flex">
                 
                                <input type="email" class="form-control" placeholder="Enter Email Address" value={newCarrier.carrier_email} name="carrier_email" onChange={handleChange} required />
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <input type="checkbox" id="sendToCarrier" checked={checked} name="autosend" onChange={handleChecked} />
                                        <label class="form-check-label" for="sendToCarrier" style={{marginBottom: 0}}>&nbsp;Auto-Send</label>

                                    </div>
                                </div>
                                {loading ?
                                    <button class="btn btn-success" disabled>Sending...</button>
                                    :
                                    <button class="btn btn-success">Submit</button>
                                }
                            </div>
                            <div className="d-flex justify-content-center error-msg-red mt-2">{error}</div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer collapse" id="addCarrier">

                <span>Want to manually add a carrier? </span><Link to="/carriers/add"> Click Here</Link>
            </div>
        </div>

    )
}