import React, { useEffect, useState } from "react";
import { useAuth, authFetch, logout } from "../../../auth"
import {
    useHistory,
    useParams
} from "react-router-dom";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";


const ItemsInfoEdit = (props) => {
    const params = useParams();
    const history = useHistory();
    const initialState = () => {
        return (props)
    }
    const [state, setState] = useState(initialState);
    const [data, setData] = useState()
    const [totalAmount, setTotalAmount] = useState()


    const handleChange = (idx) => (e) => {
        const update = data.rows.map((item, sidx) => {
            if (idx !== sidx)
                return item;
            return {
                ...item,
                [e.target.name]: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value
            }
        })
        setData({ ...data, rows: update })
    }
    const handleAddRow = () => {
        const newItem = {
            description: "",
            quantity: 1,
            amount: 0,
        };
        setData({
            rows: [...data.rows, newItem]
        });
    };
    const handleRemoveSpecificRow = (idx) => () => {
        const rows = [...data.rows]
        rows.splice(idx, 1)
        setData({ rows })
    }

    useEffect(() => {
        if (props && props.items) {

            setData(prevState => {
                return { ...prevState, rows: props.items };
            });
            


        }
        else {
            setData({
                rows: [{
                    description: "",
                    quantity: 1,
                    amount: 0
                }]
            })
        }
    }, [props.items])

    useEffect(() => {
        if (data && data.rows) {
            setTotalAmount(data.rows.reduce((a, v) => a = a + v.quantity * v.amount, 0))
        }
    }, [data])


    const handleSubmit = e => {
        //load_id: params.invoice_id - might need to look at to link to correct load?

        e.preventDefault()
        authFetch(`https://fltbd.herokuapp.com/api/invoice/${props.invoice_id}`, {
            method: 'PATCH',
            body: JSON.stringify({

                invoice_number: props.invoice_number,
                date: moment(props.date).format('l'),
                due_date: moment(props.due_date).format('l'),
                customer_id: props.customer_id,
                customer_name: props.customer_name,
                customer_billing_address: props.customer_billing_address,
                customer_phone_number: props.customer_billing_phone_number,
                customer_billing_email: props.customer_billing_email,
                items: [data.rows],
                total: props.total,
                invoice_note: "Note Not Required",
                status: props.status,
                terms: props.terms,
                

            }),
            headers: { 'Content-Type': 'application/json' },

        })
        //history.push("/accounting/ready");


        const newInvoice = {

            invoice_number: props.invoice_number,
                date: moment(props.date).format('l'),
                due_date: moment(props.due_date).format('l'),
                customer_id: props.customer_id,
                customer_name: props.customer_name,
                customer_billing_address: props.customer_billing_address,
                customer_phone_number: props.customer_billing_phone_number,
                customer_billing_email: props.customer_billing_email,
                items: [props.items],
                total: props.total,
                invoice_note: "Note Not Required",
                status: "Invoiced",
                terms: props.terms,
        }

        // Not Needed, I think this is for Layout
        //props.handleUpdate(newInvoice, params.invoice_id)

    }
    return (
        <>

            <h3>Items</h3>

            <div class="table-responsive">

                <table>
                    <thead>
                        <tr style={{ borderTop: "1px solid black" }}>

                            <th className="w-75" style={{ borderBottom: "1px solid black", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Description</th>
                            <th style={{ borderBottom: "1px solid black", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Quantity</th>
                            <th style={{ borderBottom: "1px solid black", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Amount</th>
                            <th style={{ borderBottom: "1px solid black", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}></th>

                        </tr>
                    </thead>
                    {data && data.rows.map((item, idx) =>
                        <tr key={idx}>
                            <td className="w-75">
                                <input className="form-control" name="description" value={item.description} onChange={handleChange(idx)} readOnly={!props.edit} />
                            </td>
                            <td>
                                <input className="form-control" type="num" name="quantity" value={item.quantity} onChange={handleChange(idx)} readOnly={!props.edit} />
                            </td>

                            <td>
                                
                                <input className="form-control" type="text" name="amount" value={"$".toString()+item.amount} onChange={handleChange(idx)} readOnly={!props.edit} />
                            </td>
                            <td>
                                {!props.edit ?
                                    null
                                    :
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={handleRemoveSpecificRow(idx)}
                                    >
                                        Remove
                                    </button>
                                }
                            </td>
                            <br />
                            <br />
                            <br />


                        </tr>



                    )}

                </table>
                <h4 className="float-right">Subtotal: ${totalAmount} </h4> <br />
                {!props.edit ? null :
                    <button onClick={handleAddRow} className="btn btn-primary"> Add Item </button>
                }

            </div>
            {!props.edit ? null :
            <form onSubmit={handleSubmit}>
                <button class="btn btn-success btn-lg float-right">Save Invoice</button>

            </form>
            }
        </>
    )
}

export default ItemsInfoEdit