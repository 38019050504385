
import React, { useMemo, useState, useEffect } from "react";
import { useHistory, withRouter, useParams } from "react-router-dom";
import NotFound from "../Home/NotFound";


export default function LoadsDocuments(props) {
    const params = useParams();
    const [data, setData] = useState({})
    const [notFound, setNotFound] = useState(false)
    const [bol, setBol] = useState()
    const [unsigned, setUnsigned] = useState()
    const [confirmationLink, setConfirmationLink] = useState()
    const [loading, setLoading] = useState(true)

    const placeHolder = () => {
        return (

            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-center">
                        <h2>Loading Load Documents</h2> <br />
                    </div>
                    <br />
                    <div class="d-flex justify-content-center">
                        <div class="spinner-grow" style={{ width: '1rem', height: '1rem' }}></div>
                        <div class="spinner-grow" style={{ width: '1rem', height: '1rem' }}></div>
                        <div class="spinner-grow" style={{ width: '1rem', height: '1rem' }}></div>
                    </div>
                </div>
            </div>
        )
        }


    useEffect(() => {
        if (props && props.loads) {
            const loadInfo = props.loads.filter(load => load.load_id == params.load_id)
            setData(loadInfo[0])
            
            if(loadInfo[0] === undefined || loadInfo[0].length > 0) {
                setNotFound(true)
                setLoading(false)
            }
            else {
                setLoading(false)
                setNotFound(false)
            }


            setBol(`https://fltbd-bucket.s3.us-east-2.amazonaws.com/bol_${params.load_id}.pdf`)
            setUnsigned(`https://fltbd-bucket.s3.us-east-2.amazonaws.com/unsigned_${params.load_id}.pdf`)
            setConfirmationLink(`https://fltbd.com/documents/rateconfirmation/${params.load_id}`)
        }
        else {
            setNotFound(true)
        }
    }, [props.loads])

    if(loading) {
        return (
            <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                {placeHolder()}
            </div>
        )
    }

    
    return (
        
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            {notFound ? 
                <NotFound />
            :
            <div class="d-sm-flex align-items-center justify-content-between">
                <h1>Load Documents for #{data.load_number}</h1>
            </div>
            }
             {notFound ? null :
            <div class="card-table table-responsive">
                <table class="table table-vcenter color-white">
                    <thead>
                        <tr style={{borderTop: "1px solid black"}}>
                            <th style={{borderBottom: "1px solid black", color:"black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0"}}>Document Type</th>
                            <th style={{borderBottom: "1px solid black", color:"black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0"}}>Descripiton</th>
                            <th style={{borderBottom: "1px solid black", color:"black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0"}}></th>

                        </tr>
                    </thead>
                    <tr>
                        <td>Rate Confirmation</td>
                        <td class="" >The customer confirmation document for this load. To be sent to the customer on the load to verify agreed upon terms including payment on the load.</td>
                        <td class="">
                            <div class="flex-nowrap">
                            <a href={unsigned} class="btn btn-info mb-3">
                                Unsigned
                            </a>
                            
                            <a href={confirmationLink} class="btn btn-primary">
                                Confirmation Link
                            </a>
                            
                        </div></td>
                    </tr>
                    <tr>
                        <td>Bill of Lading</td>
                        <td class="" >The BOL document for this load. Contains the details of the load and includes signature areas for the consignor, consignee, and driver on the load.</td>
                        <td class="">
                            <div class="flex-nowrap">
                            <a href={bol} class="btn btn-primary">
                                Download
                            </a>
                            
                        </div></td>
                    </tr>
                    <tr>
                        <td>Invoice</td>
                        <td class="" >The invoice document for this load. To be sent to the customer on the load for payment after the load has been delivered.</td>
                        <td class="">
                            <div class="flex-nowrap">
                            <a href="#" class="btn btn-primary">
                                Download
                            </a>
                            
                        </div></td>
                    </tr>
                </table>
            </div>
        }
        </div>
    )

}