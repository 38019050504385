import React, { useState, useEffect } from 'react';


const Subscription = (props) => (
  
    
  <section>
    <div className="product">

      <div className="description">
        <h3>Starter plan</h3>
        <h5>$20.00 / month</h5>
      </div>
    </div>
    <form action="https://fltbd.herokuapp.com/api/stripe-create-checkout" method="POST">
      {/* Add a hidden field with the lookup_key of your Price */}
      <input type="hidden" name="lookup_key" value="fltbd-sub" />
      <input type="hidden" name="stripe_customer_id" value={props.stripeCustomerID} />
      <input type="hidden" name="customer_email" value={props.email} />
      <button id="checkout-and-portal-button" type="submit">
        Checkout
      </button>
    </form>
  </section>
);

const SuccessDisplay = ({ sessionId }) => {
  return (
    <section>
      <div className="product Box-root">
    
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <form action="https://fltbd.herokuapp.com/create-portal-session" method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </button>
      </form>
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);
const ManageSubScription = (props) => {
    return(
        <form action="https://fltbd.herokuapp.com/api/stripe-customer-portal" method="POST">
        <input
          type="hidden"
          id="customer-id"
          name="stripe_customer_id"
          value={props.stripeCustomerID}
        />
        <button id="checkout-and-portal-button" type="submit">
          Manage your subscription
        </button>
      </form>
    )
}
export default function SettingsSubscription(props) {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  const [stripeSubID, setStripeSubID] = useState()
  const [stripeCustomerID, setStripeCustomerID] = useState()
  const [email, setEmail] = useState()

  
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  useEffect(() => {
    if(props && props.stripe && props.email) {
      setStripeSubID(props.stripe[0].stripe_subscription_id)
      setStripeCustomerID(props.stripe[0].stripe_customer_id)
      setEmail(props.email)
    }
  }, [props.stripe],[props.email])


  if (!success && message === '') {
    return (
        <>
          <div className="card">
          <div class="card-header">
                <h3 class="card-title">Subscription</h3>
            </div>
            <div className="card-body">
            <Subscription email={email} stripeCustomerID={stripeCustomerID} />
            <ManageSubScription  stripeSubID={stripeSubID} stripeCustomerID={stripeCustomerID} />
              <hr />
            </div>
          </div>
    </>
    )
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
  
}

