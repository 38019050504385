import { useState, useEffect } from "react";
import { useAuth, authFetch } from "../../auth";
import DOMPurify from 'dompurify';
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";



const Styles = styled.div`
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Rock+Salt|Shadows+Into+Light|Cedarville+Cursive');
@page {
    size: Letter;
    margin: 4em 2em;
    
    margin-top: 2em;
   

    @bottom-right {
      content: "fltbd.com";
      font-size: 0.8em;
      font-style: italic;
      font-family: Arial, Helvetica, sans-serif;
    }
    /* 
    @top-left {
        content: "setupPack.com";
        font-size: 0.8em;
    }
    */
  }
  @page :first {

  }
  
  @page ppe_page {

  }



  html {
    color: #000;
    font-family: sans;
    font-size: 10pt;
    line-height: 1.4;
  }
  
  
  h1 {
      color: #000;
    font-weight: 400;
  }
  
  h2 {
    color: #27b;
    font-size: 1em;
    margin: 2em 0 0 0;
    text-transform: uppercase;
  }
  .h8 {
    color: #27b;
    font-size: 1.25em;
    margin: 2em 0 0 0;
    text-transform: uppercase;
  }
  
  p {
    margin: 0.5em 0;
    text-align: justify;
  }
  
  
  aside {
    background: #eee;
    float: right;
    margin-left: 1em;
    width: 30%;
  }
  
  aside embed {
    margin-bottom: 1em;
    width: 100%;
  }
  
  aside article {
    margin: 1em;
  }
  
  aside article:not(article:last-child) {
    border-bottom: 1px solid #aaa;
  }
  
  
  #secondary {
    
    text-align: right;
    width: 100%;
  }
  
  #secondary article {
    display: table-cell;
    padding-right: 1em;
    border-bottom:1px solid black;
    padding: 20px;
  }
  
  #secondary p {
    font-family: 'Cedarville Cursive', cursive;
    font-size: 1.8em;
    font-size: 3.5vw;
    padding: 1em;
    border-bottom: 1px solid black;
    
  }
  
    #secondary p {
 
    padding-bottom: 0em;
    
  }
  embed {
    width: 60%;
  }
  /* 
  li
{
    border-bottom: 2px solid black;
    width: 725px;
    list-style-type: none;
    padding-top: 20px
}
  */

label
{
    border-bottom: 2px solid white;
    padding-right: 5px;
}

* {
    box-sizing: border-box;
  }
  
  .box {
    float: left;
    width: 33.33%;
    padding: 0px;
  }
  .box2 {
    float: left;
    width: 50%;
    
  }
  
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

  .box p {
    margin: 0.5em 0;
    text-align: justify;
    font-size: 10pt;
    text-align:right;
  }
  table, th, td {
  
    border-collapse: collapse;
    
  }
  th, td {
    padding: 5px;
    text-align: left;
    font-size: 16px;
  }
  tr {
    padding-bottom:20px;
  }
  .ppe {
    page: ppe_page;
    page-break-before: always;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    margin: 0.65in;
    margin-top: .30in;
  }

  .ppe-title {
    margin-bottom: 30px;
    color: red;
    font-size: 25px;
    font-weight: bold;
  }

  .ppe-alert  {
    color: red;
    font-size: 18px;
  }
  
  .ppe li{
    border: none;
    list-style-type: disc;
  }

  .contract {
    page-break-before: always;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin-right: 0.65in;
    margin-left: 0.65in;
  }

  .contract-bold {
    font-weight: bold;
  }
  
  .contract-underline {
    border-bottom: 1px solid black;
  }

  .contract li {
    list-style-type: none;
  }

  .contract tr {
    width:50%; 
    text-align: center;
  }

  .contract td {
    font-weight: bold;
    text-align: center;
  }

  .contract th {
    text-align: center;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }

  .ppe .footer{
    display: none;
  }

  .broker{

    width: 50%;
}
.carrier{

    width: 50%;
}

.container2 {
  display:flex;
}
.profile_signed {
    border-bottom: 1px solid black;    
    width: 70%;

}
.profile_date {
    border-bottom: 1px solid black;
    width: 30%;

}

.container2 span {
  font-size: 30px;
}

.profile_signed {
  border-bottom: 1px solid black;    
  width: 70%;

}
.profile_date {
  border-bottom: 1px solid black;
  width: 30%;

}

.container2 span {
font-size: 30px;
}


table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
}
th {
  border-bottom: 1px solid black;
  
  font-size: 10pt;
  font-weight: 400;
  padding-bottom: .25cm;
  text-transform: uppercase;
}
td {
  padding-top: 0;
}
/* 
td:last-of-type {
  color: #1ee494;
  font-weight: bold;
  text-align: right;
}
*/
th, td {
  text-align: center;
}
th:first-of-type, td:first-of-type {
  text-align: left;
}
th:last-of-type, td:last-of-type {
  text-align: right;
}
table#total {
  background: #f6f6f6;
  border-color: #f6f6f6;
  border-style: solid;
  border-width: 2cm 3cm;
  bottom: 0;
  font-size: 20pt;
  margin: 0 -3cm;
  position: absolute;
  width: 18cm;
}

.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


.sec-column {
  float: left;
  width: 33.3%;
}

/* Clear floats after the columns */
.sec-row:after {
  content: "";
  display: table;
  clear: both;
}

.align-right {
  text-align: right;
}

.float-right {
  float:right;
}
.float-left {
  float: left;
}

.rate-confirmation-title {
  font-weight: bold;
  font-size: large
}

.bottom-border {
  border-bottom: 1px solid black;
}
.rate {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 1rem;
}
hr {
  margin: 0px
}

`

export default function RateConfirmationDemo(props) {
  const initialState = () => {
    return (props)
  }
  const [state, setState] = useState(props);



  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }


  return (

    <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
      <div class="card">
        <div class="card-body">
       
          <Styles>
            <div class="row">
              <div class="column">
                <p class="rate-confirmation-title">Rate Confirmation</p>
              </div>
              <div class="column float-right align-right">
                <p class="align-right">Load Number: 16180</p>
              </div>
            </div>

            <hr />
            <div class="sec-row">
              <div class="sec-column">
                <b>Broker:</b><br />
                <b>Jordan Brokers Inc.</b><br />
                2607 Schultz Meadow Ave<br />
                Bethview, KY 44413  <br />
                505-503-4455
                </div>
              <div class="sec-column">
                <b>Carrier:</b><br />
                <b>The Trucking Company LLC</b><br />
                577 Parker Glen Loop<br />
                Claire, WY 14491 <br />
                605-475-6961<br />
                DOT: 445884123&nbsp;
                MC: 6772901 <br />
                
              </div>
              <div class="sec-column float-right">
                <b>Load:</b><br />
                Trailer: Flatbed<br />
                Equipment: Chains & Binders, Straps
                <br />
                Distance: 350 miles
              </div>
            </div>
            <hr />
            <br />
            <h3>Shippers:</h3>
            <table style={{border: "none"}}>
              
              <tbody>
                
                  <tr class="border border-dark" >
                    
                    <td class="pl-3 pt-2">
                      <b>Nelson-Stanley Pipe Co.</b>
                      <div>874 Myers St. Moon City, AZ 87405</div>
                      <div>Ricky 248-434-5508</div>
                      <div class=" p-2" >
                        <div> Pick Up Number: <b>#589815</b></div>
                        <div> Cargo Description: <b>25' Steel Pipe & 100KW Generator</b></div>
                        <div> Extra Note: <b>** CALL RICKY 1 HOUR FROM PICK UP **</b></div>
                      </div>
                                                                    
                    </td>
                    
                 
                    <td class="pr-2">                    
                      1/11/2023
                    </td>
                    
                  </tr>
                  
                  

               
                
              </tbody>
            </table>
            <br />
            <h3>Receivers:</h3>
            <table style={{border: "none"}}>
              
              <tbody>
               
                  
                  <tr class="border border-dark">
                                     
                    <td class="pl-3 pt-2">
                      <b>Super Green Sod. Co</b>
                      <div>4453 Quinn Ave Angel Grove, CA. 98771</div>
                      <div>Nick 603-413-4124</div>
                     
                    </td>
                   
                    <td class="pr-2">
                    
                    1/12/2023
                    </td>
                  </tr>
        
              </tbody>
            </table>
            
            <div class="rate align-right">
              <b>Rate: $1700 </b>
            </div>
            <div class="company_tc markdown mb-2">
              <div className="text-center"><h3>Terms & Conditions</h3></div>
              <div className="company_tc markdown">
              On all job sites, it is mandatory to wear boots, long pants, safety vests, and hard hats. Shorts, sandals, and tank tops are strictly prohibited. 
              This rate covers all expenses related to loading, transportation, fuel surcharges, unloading, and delivering the freight in good condition, free of shortage, damage, or delay. 
              The carrier must maintain the minimum liability insurance required by law at all times during transport. 
              If the carrier brokers this load to another carrier or uses a substitute service on the railway, the broker reserves the right to charge the carrier a penalty in the form of the total amount owed on this rate confirmation and pay the carrier who actually moved the load. 
              Late delivery will result in a penalty of $500 per day or $100 per hour for appointments, or whichever amount the shipper/receiver charges per day/hour. 

              </div>
            </div>
          </Styles>
          
            <div class="form-row" style={{ border: '1px solid black', padding: '10px' }}>
              <div class="form-group col-md-6">
                <label >Driver Name</label>
                <input type="text" class="form-control" placeholder="Driver Name" disabled />
              </div>
              <div class="form-group col-md-6">
                <label>Driver Phone Number</label>
                <input type="text" class="form-control" placeholder="Driver Phone Number"  disabled />
              </div>
              <div class="form-group col-md-6">

              </div>
              <div class="form-group col-md-6">
                <label>Carrier Signature</label>
                <input type="text" class="form-control" placeholder="Carrier Signer Name" disabled />
              </div>
            </div>
            <div class="text-center" style={{ marginTop: "30px" }}>
              <button class="btn btn-success btn-lg" disabled>Accept</button>
            </div>
          
          <p class="text-muted text-center mt-5">binding ID#{uuidv4()}</p>




        </div>
      </div>
    </div>


  )
}

