import React, { Component, useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch,
  Redirect,
  useParams
} from "react-router-dom";

import styled from "styled-components";
import { useAuth, logout, authFetch } from "../auth"

const Styles = styled.div`
.navbar-overlap:after {
  content: '';
  height: 9rem;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: inherit;
  z-index: -1;
  box-shadow: inherit;
}
`
const brokerHeader = () => {
  return (
    <>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/dashboard">Dashboard</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/loads">Loads</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/customers">Customers</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/carriers">Carriers</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/locations">Locations</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/accounting">Accounting</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/reports">Reports</Link>
        </span>
      </li>
    </>
  )
}
const carrierHeader = () => {
  return (
    <>
      <li class="nav-item">
        <span class="nav-link-title">
          <a className="nav-link" href="/dashboard">Dashboard</a>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <a className="nav-link" href="/loads">Loads</a>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/customers">Customers</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/locations">Locations</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/equipment">Equipment</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/drivers">Drivers</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/accounting">Accounting</Link>
        </span>
      </li>
    </>
  )
}

const bothHeader = () => {
  return (
    <>
      <li class="nav-item">
        <span class="nav-link-title">
          <a className="nav-link" href="/dashboard">Dashboard</a>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <a className="nav-link" href="/loads">Loads</a>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/customers">Customers</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/carriers">Carriers</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/locations">Locations</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/equipment">Equipment</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/drivers">Drivers</Link>
        </span>
      </li>
      <li class="nav-item">
        <span class="nav-link-title">
          <Link className="nav-link" to="/accounting">Accounting</Link>
        </span>
      </li>
    </>
  )
}

export default function Header(props) {
  const [logged] = useAuth();
  const [userInfo, setUserInfo] = useState()
  const [state, setState] = useState()
  useEffect(() => {
    setState(props)
  }, [])
  
  

  return (
    <div>
      {logged ?
        <header class="navbar navbar-expand-md navbar-dark navbar-overlap d-print-none">
          <div class="container-xl">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
              <span class="navbar-toggler-icon"></span>
            </button>
            <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
              <a href="/dashboard">
                <h1>fltbd</h1>
              </a>
            </h1>
            <div class="navbar-nav flex-row order-md-last">
              
              <div class="nav-item dropdown">
                
                <a href="#"  class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                
                  <span class="avatar avatar-sm" >
                    {props.initials}
                    {!props.company_tc || !props.company_contract ?
                     
                      <span class="badge bg-red blinking-alert">!</span>
                      
                      : null}
                   
                  </span>
                  <div class="d-none d-xl-block ps-2">
                    <div>User: {props.first_name}</div>
                    <div class="mt-1 small text-muted">title: {props.roles}</div>
                  </div>
                </a>
                <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <Link to="/settings">
                    <a href="#" class="dropdown-item">Settings</a>
                  </Link>
                  <div class="dropdown-divider"></div>
                  <a onClick={() => logout()} class="dropdown-item">Logout</a>
                </div>
              </div>
            </div>
            <div class="collapse navbar-collapse" id="navbar-menu">
              <div class="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
                <ul class="navbar-nav">
                  
                {props.type === "broker" ? 
                brokerHeader() :
                props.type === "carrier" ?
                carrierHeader() :
                props.type === "both" ?
                bothHeader()
                : null }
                 
                </ul>
              </div>
            </div>
          </div>
        </header>
        :
        <header class="navbar navbar-expand-md navbar-dark navbar-overlap d-print-none">
          <div class="container-xl">
            <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
              <a href="/">
                <h1>fltbd</h1>
              </a>
            </h1>
            <div class="navbar-nav flex-row order-md-last">
              <div class="nav-item dropdown">
                <div class="d-xl-block ps-2">
                  <Link to="/login">
                    <div class="mt-1 small text-muted">Log-In</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
      }
    </div>


  );
}



