import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import {
    OverlayTrigger,
    Tooltip,
    Modal,
    Button
} from "react-bootstrap";





const OnboardModal = (props) => {

    const initialState = () => {
        return (props)
    }
    const history = useHistory();

    const [state, setState] = useState(initialState);
    const [showModal, setShow] = useState(true);
    const [checkList, setChecklist] = useState([])



    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setState(props);
    }


    useEffect(() => {
        console.log(props.stripe.stripe_subscription_id)
    })



    return (
        <>


            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"

            >

                <Modal.Header closeButton>
                    <Modal.Title>Set Up Check List</Modal.Title>
                </Modal.Header>

                <>
                    <Modal.Body class="modal-body">
                        <p>You are almost done... </p>
                        <p>But there are a few things that need to be done before you can use the app.</p>
                        <br />
                        <div className="mb-3">
                            <h3>Mandatory:</h3>

                            {props.stripe.stripe_subscription_id === undefined
                                ?
                                <div class="list-group-item">
                                    <div class="row">

                                        <div class="col ">
                                            <a href="/settings/subscription" class="text-body d-block"><b>Start Free Trail</b></a>
                                            <div class="text-muted  mt-n1">Start your 15 day trial. No credit card required.</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class="list-group-item" style={{ textDecoration: "line-Through" }}>
                                    <div class="row">
                                        <div class="col ">
                                            <a href="/settings/subscription" class="text-body d-block"><b>Start Free Trail</b></a>
                                            <div class="text-muted  mt-n1">Start your 15 day trial. No credit card required. </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {props.company_contract === ""
                                ?
                                <div class="list-group-item">
                                    <div class="row">

                                        <div class="col ">
                                            <a href="/settings/contract-settings" class="text-body d-block"><b>Contract Settings</b></a>
                                            <div class="text-muted  mt-n1">Add your broker to carrier agreement terms - this will be visible on every carrier packet. </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class="list-group-item" style={{ textDecoration: "line-Through" }}>
                                    <div class="row">
                                        <div class="col ">
                                            <a href="/settings/contract-settings" class="text-body d-block"><b>Contract Settings</b></a>
                                            <div class="text-muted  mt-n1">Add your broker to carrier agreement terms - this will be visible on every carrier packet. </div>
                                        </div>
                                    </div>
                                </div>
                            }



                            {props.company_tc === ""
                                ?
                                <div class="list-group-item">
                                    <div class="row">
                                        <div class="col ">
                                            <a href="/settings/confirmation-settings" class="text-body d-block"><b>Rate Confirmation Settings</b></a>
                                            <div class="text-muted  mt-n1">Add your terms to your rate confirmation - this will be visible on every rate confirmation.</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class="list-group-item" style={{ textDecoration: "line-Through" }}>
                                    <div class="row">
                                        <div class="col ">
                                            <a href="/settings/confirmation-settings" class="text-body d-block"><b>Rate Confirmation Settings</b></a>
                                            <div class="text-muted  mt-n1">Add your terms to your rate confirmation - this will be visible on every rate confirmation.</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {props.customers.length === 0
                                ?
                                <div class="list-group-item">
                                    <div class="row">
                                        <div class="col ">
                                            <a href="/customers" class="text-body d-block"><b>Add Customer(s)</b></a>
                                            <div class="text-muted mt-n1">You need to have at least one customer before creating rate confirmations.</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class="list-group-item" style={{ textDecoration: "line-Through" }}>
                                    <div class="row">
                                        <div class="col ">
                                            <a href="/settings/confirmation-settings" class="text-body d-block"><b>Add Customer(s)</b></a>
                                            <div class="text-muted mt-n1">You need to have at least one customer before creating rate confirmations.</div>
                                        </div>
                                    </div>
                                </div>
                            }
                           
                            {props.carriers.length === 0 
                                ?
                                <div class="list-group-item">
                                    <div class="row">
                                        <div class="col ">
                                            <a href="/carriers" class="text-body d-block"><b>Add Carrier(s)</b></a>
                                            <div class="text-muted mt-n1">You need to have at least one carrier before creating rate confirmations.</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class="list-group-item" style={{ textDecoration: "line-Through" }}>
                                    <div class="row">
                                        <div class="col ">
                                            <a href="/carriers" class="text-body d-block"><b>Add Carrier(s)</b></a>
                                            <div class="text-muted mt-n1">You need to have at least one carrier before creating rate confirmations</div>
                                        </div>
                                    </div>
                                </div>
                            }
                             {props.locations.length < 2 
                                ?
                                <div class="list-group-item">
                                    <div class="row">
                                        <div class="col ">
                                            <a href="/locations" class="text-body d-block"><b>Add Locations</b></a>
                                            <div class="text-muted mt-n1">You need to have at least two locations before creating rate confirmations. These are your shippers & receivers.</div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class="list-group-item" style={{ textDecoration: "line-Through" }}>
                                    <div class="row">
                                        <div class="col ">
                                            <a href="/locations" class="text-body d-block"><b>Add Locations</b></a>
                                            <div class="text-muted mt-n1">You need to have at least two locations before creating rate confirmations. These are your shippers & receivers.</div>
                                        </div>
                                    </div>
                                </div>
                            }


                        </div>

                        <h3>Optional:</h3>
                        <div class="list-group-item">
                            <div class="row">
                                <div class="col ">
                                    <a href="/settings/load-number-settings" class="text-body d-block"><b>Load Number</b></a>
                                    <div class="text-muted mt-n1">Change your starting load number</div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Do It Later
                        </Button>

                    </Modal.Footer>
                </>

            </Modal>


        </>
    )
}

export default OnboardModal