import React, { useState, useEffect, Component, useRef } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Select from 'react-select'
import axios from 'axios'
import { authFetch } from '../../auth';

const LoadsFinances = (props) => {
    const initialState = () => {
        return (props)
    }
    const [state, setState] = useState(initialState);
    const [timer, setTimer] = useState(null)
    useEffect(() => {

    }, [])
    const [focus, setFocus] = useState(false)
    function grossPay(e) {
        clearTimeout(timer)
        //Testing the handleGrossFocus instead of this...
        //const newTimer = setTimeout(() => {
        //    authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
        //        method: 'PATCH',
        //        body: JSON.stringify({
        //            [e.target.name]: e.target.value
        //        }),
        //        headers: { 'Content-Type': 'application/json' },
        //    })
       // }, 500)
        //setTimer(newTimer)
        const value = parseInt(e.target.value)
        const NaNChecker = (isNaN(value) ? 0 : value)
        props.parentLoadDetails({ gross_pay: NaNChecker })

    }
    function carrierPay(e) {
        clearTimeout(timer)
        const value = parseInt(e.target.value)
        const NaNChecker = (isNaN(value) ? 0 : value)
        props.parentLoadDetails({ carrier_pay: NaNChecker })
    }
    function handleFocus(e) {
        setFocus(false)
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                [e.target.name]: e.target.value
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        //props.parentLoadDetails({gross_pay: e.target.value})

    }
    return (


        <div class="card">
            <div class="card-body">
                <h3 class="card-title">Finances</h3>

                <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-sm">Customer Pay</span>
                    </div>
                    <input
                        onBlur={(e) => handleFocus(e)}
                        onFocus={() => setFocus(true)}
                        type="num"
                        value={props.gross_pay}
                        name="gross_pay"
                        class="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(e) => grossPay(e)} />
                </div>
                {!props.company_driver ?
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-sizing-sm">Carrier Pay</span>
                        </div>
                        <input 
                        onBlur={(e) => handleFocus(e)}
                        onFocus={() => setFocus(true)}
                        type="text" 
                        pattern="[0-9]*" 
                        value={props.carrier_pay} 
                        name="carrier_pay" 
                        class="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm" 
                        onChange={(e) => carrierPay(e)} />
                    </div>
                    :
                    null
                }
            </div>
        </div>




    )


}

export default LoadsFinances