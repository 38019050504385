import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Select from 'react-select'
import axios from 'axios'
import { authFetch } from '../../auth';

const LoadsCustomer = (props) => {
    const initialState = () => {
        return (props)
    }
    const [state, setState] = useState(initialState);
    const history = useHistory();

    function customerChange(e) {
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                customer_name: e.label
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        props.parentLoadDetails({customer_name: e.label})
    }
   
      useEffect(() => {
        if (props && props.customers) {        
        const options = props.customers.map(d => ({
          "value": d.id,
          "label": d.customer_name,
          "customer_id": d.customer_id
        }))
        setState({customers: options})
        }
    }, [props.customers])
   

    return (
       
        
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title">Customer</h3>
                        <div class="mb-3">
                            <label class="form-label">Customer Name</label>
                            <Select options={state.customers} placeholder={props.customer_name} name="customer_name" onChange={(e) => customerChange(e)} />
                        </div>

                    </div>
                </div>

        

        
    )


}

export default LoadsCustomer