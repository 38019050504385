import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from '../../auth';


export default function CustomersItemBillingDetails(props) {
    const [state, setState] = useState('')
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }
   
   
    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        props.parentCustomerDetails({[e.target.name]: e.target.value})
        //props.updateCustomer({[e.target.name]: e.target.value, customer_id: props.customer_id})
        //props.updateSingle({[e.target.name]: e.target.value, customer_id: props.customer_id, type: "customers", id: props.id})

    }


    useEffect(() => {
        setState(props)
    })

    const handleSubmit = e => {
        e.preventDefault();
        authFetch(`https://fltbd.herokuapp.com/api/customers/${props.customer_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                billing_contact_name: state.billing_contact_name,
                billing_email: state.billing_email,
                billing_phone_number: state.billing_phone_number,
                billing_address: state.billing_address,
                billing_state: state.billing_state,
                billing_city: state.billing_city,
                billing_zipcode: state.billing_zipcode,
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        setEdit(!edit);
        props.updateCustomer(state)
    }

    return (
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Billing Details</h3>
                <div class="edit-button">
                    <a href="#" onClick={changeEdit}> [edit]</a>
                </div>
            </div>
            <div class="card-body">
                <form onSubmit={handleSubmit}>
                    <div class="form-row">
                        <div class="col-md-4 mb-3">
                            <label class="form-label">Contact Name</label>
                            <input type="text" class="form-control" value={state.billing_contact_name} name="billing_contact_name" onChange={handleChange} required readOnly={edit} />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" value={state.billing_email} name="billing_email" onChange={handleChange} readOnly={edit} />

                        </div>
                        <div class="col-md-4 mb-3">
                            <label class="form-label">Phone Number</label>
                            <div class="input-group">

                                <input type="text"
                                    class="form-control"
                                    value={state.billing_phone_number}
                                    name="billing_phone_number"
                                    onChange={handleChange}
                                    required readOnly={edit} />
                            </div>
                        </div>
                    </div>


                    <div class="form-group">
                        <label class="form-label" for="inputAddress">Address</label>
                        <textarea type="text" class="form-control" value={state.billing_address} name="billing_address" onChange={handleChange} readOnly={edit} />

                    </div>

                    
                        
                    {!edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                        </div>
                        : null}
                </form>
            </div>

        </div>
    )
}