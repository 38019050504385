import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from '../../auth';

export default function CustomersItemCustomerDetails(props) {
    const [state, setState] = useState('')
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }

    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        props.parentCustomerDetails({[e.target.name]: e.target.value})
        //props.updateCustomer({[e.target.name]: e.target.value, customer_id: props.customer_id})
    }

    useEffect(() => {
        setState(props)
    })
    const handleSubmit = e => {
        e.preventDefault();
        authFetch(`https://fltbd.herokuapp.com/api/customers/${props.customer_id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            customer_name: state.customer_name,
            contact_name: state.contact_name,
            email: state.email,
            phone_number: state.phone_number
          }),
          headers: { 'Content-Type': 'application/json' },
        })
        setEdit(!edit);
        props.updateCustomer(state)
    }
    return (
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Customer Details</h3>
                <div class="edit-button">
                    <a href="#" onClick={changeEdit}> [edit]</a>

                </div>
            </div>
            <div class="card-body">
            <form onSubmit={handleSubmit}>
            <div class="form-row">
                <div class="col mb-3">
                <label class="form-label " for="inputCustomerName">Customer Name</label>
                <input type="text" class="form-control" value={state.customer_name} name="customer_name" onChange={handleChange} readOnly={edit} />
                </div>
            </div>
                <div class="form-row">
                    <div class="col-md-4 mb-3">
                        <label class="form-label">Contact Name</label>
                        <input type="text" class="form-control"  value={state.contact_name} name="contact_name"  onChange={handleChange} required readOnly={edit}  />
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="form-label">Email</label>
                        <input type="text" class="form-control"  value={state.email} name="email" onChange={handleChange}  readOnly={edit}  />
                        
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="form-label">Phone Number</label>
                        <div class="input-group">
                            <input type="tel"
                                class="form-control"
                                value={state.phone_number}
                                name="phone_number"
                                onChange={handleChange}
                                readOnly={edit}  />
                        </div>
                    </div>
                </div>
                {!edit ?
                    <div>
                        <br />
                        <button type="submit" className="btn btn-success float-right">Make Changes</button>
                    </div>
                    : null}
            </form>
            </div>
            
        </div>
    )
}